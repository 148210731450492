import "./styles/ListNavButton.css";

function ListNavButton({name, hue})
{
    function handleClick()
    {

    }

    return (
        <div class="list-nav-button" style={{'--hue': hue}} onCLick={handleClick}>{name}</div>
    );
}

export default ListNavButton;