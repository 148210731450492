import "./styles/ListItem.css";

import { useState } from "react";
import { checkItem } from '../ApiHook'

function ListItem({item, list})
{
    const [completed, setCompleted] = useState(false);

    function handleClick(e)
    {
        if (completed)
            return;
        e.target.parentElement.classList.add('listItem-clear');
        setCompleted(true);
    }

    async function onAnimationEnd(e)
    {
        e.target.style.display = 'none';
        const response = await checkItem(item.id, list.id);
        if (response.RESULT === 'success')
        {
            e.target.remove();
        }else
        {
            setCompleted(false);
            e.target.style.display = '';
            e.target.getElementsByTagName('input')[0].checked = false;
            e.target.classList.remove('listItem-clear');
            alert('Unexpected error');
        }
    }

    return (
        <label class='listItem' onAnimationEnd={onAnimationEnd}>
            <input type='checkbox' onChange={handleClick}></input>
            {item.name}
        </label>
    );
}

export default ListItem;