import "./styles/Popup.css";

import { useState } from "react";

function Popup({content, popUpControl})
{
    const [open, setOpen] = useState(false);

    function handleClick()
    {

    }

    popUpControl.current = {
        open: function ()
        {
            setOpen(true);
        },
        toggle: function ()
        {
            const setStatus = !open;
            setOpen(setStatus);
        },
        close: function ()
        {
            setOpen(false);
        },
    };

    function close()
    {
        setOpen(false);
    }

    return (
        <div class='list-add-popup' open={open}>
            <div class='list-add-popup-inside'>
                <span onClick={close} class="material-symbols-outlined list-add-popup-closebtn">close</span>
                <div class='list-add-popup-content'>
                    {content}
                </div>
            </div>
        </div>
    );
}

export default Popup;