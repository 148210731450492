import "./styles/ListItemAdd.css";
import Popup from './Popup';

import { useRef } from "react";
import { pushNewItem } from '../ApiHook.js';

function ListItemAdd({list, addItem})
{
    const popUpControl = useRef(null);
    const input = useRef(null);

    function handleClick()
    {
        console.log(popUpControl);
        popUpControl.current.open();
        input.current.focus();
    }

    async function createTodo()
    {
        if (!input.current.value)
        {
            alert('Invalid input');
            return;
        }
        const response = await pushNewItem(input.current.value, list.id);
        if (response.RESULT === 'success')
        {
            addItem(input.current.value);
            popUpControl.current.close();
            input.current.value = '';
        }else
        {
            alert('Unexpected error');
        }

    }

    return (
        <div class='list-add'>
            <span class="material-symbols-outlined" onClick={handleClick}>add</span>
            <Popup popUpControl={popUpControl} content={
                <>
                    <input onKeyDown={async (e) => {
                        if (e.key === 'Enter')
                            await createTodo();
                    }} ref={input} placeholder="New To-Do" class='list-add-popup-name'></input>
                    <div onClick={async () => await createTodo()} class='list-add-popup-button'>Create</div>
                </>
            }></Popup>
        </div>
    );
}

export default ListItemAdd;