import "./styles/ListCreateButton.css";

function ListCreateButton()
{
    function handleClick()
    {

    }

    return (
        <div class="list-nav-createlist-button" onCLick={handleClick}>Create List</div>
    );
}

export default ListCreateButton;