const apiLink = 'https://lists.api.spaceship-you.com';
const apiLinkPushItem = `${apiLink}/pushItem`;
const apiLinkQueryState = `${apiLink}/queryState`;
const apiLinkCheckItem = `${apiLink}/checkItem`;

export async function pushNewItem(name, listId)
{
    const response = await fetch(apiLinkPushItem, {
        method: 'POST',
        body: JSON.stringify(
            { name: name, list: listId}
            )
    });
    const jresponse = await response.json();
    return jresponse;
}

export async function checkItem(itemId, listId)
{
    const response = await fetch(apiLinkCheckItem, {
        method: 'POST',
        body: JSON.stringify(
            { item: itemId, list: listId}
            )
    });
    const jresponse = await response.json();
    return jresponse;
}

export async function queryState()
{
    const response = await fetch(apiLinkQueryState, {
        method: 'GET',
    });
    const jresponse = await response.json();
    return jresponse;
}