import "./styles/ListBox.css";
import List from './List.js'
import { useState } from "react";

export default function ListBox({model})
{
    const listItems = model.lists.map(l => {
        return <List list={l}></List>
    });

    return (
        <div class='list-container'>
            {listItems}
        </div>
    );
}