import './styles/List.css';
import ListItem from './ListItem';
import ListItemAdd from './ListItemAdd';
import { useState } from 'react';

export default function List({list})
{
    const [items, setItems] = useState(list.items);

    function addItem(name)
    {
        console.log(name);
        setItems([...items, {name: name}]);
    }
    console.log(list);
    let listItems = list.items ? items.map(i => {
        return <ListItem list={list} key={i.id} item={i}></ListItem>
    }) : [];
    return (
        <div class='list' style={{'--hue': list.hue}}>
            <div class='list-head'>
                <span class='list-name'>{list.name}</span>
                <ListItemAdd list={list} addItem={addItem}></ListItemAdd>
            </div>
            {listItems}
        </div>
    );
}