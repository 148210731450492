import "./styles/ListNav.css";
import ListNavButton from "./ListNavButton";
import ListCreateButton from "./ListCreateButton";

function ListNav({model})
{
    const listItems = model.lists.map(l =><ListNavButton hue={l.hue} name={l.name} id={l.id}></ListNavButton>);
    return (
        <div class="list-nav">
            {listItems}
            <ListCreateButton>Create List</ListCreateButton>
        </div>
    );
}

export default ListNav;