import './App.css';

import ListNav from './components/ListNav';
import ListBox from './components/ListBox';

import { useState } from 'react';
import { queryState } from './ApiHook';

let m = await queryState();
/*{
  lists:
  [
    {name: 'Shopping List', hue: 158, id: 'shopping_list', items: [
      {name: 'Buy breakfast', id: 0},
      {name: 'Buy deodorant', id: 1},
      {name: 'Buy snacks for Pathfinder', id: 2},
    ]},
    {name: 'Chores', hue: 60, id: 'chores', items: [
      {name: 'Clean room', id: 3},
      {name: 'Clean office', id: 4},
      {name: 'Put away laundry', id: 5},
    ]},
    {name: 'Work', hue: 30, id: 'hobby', items: [
      {name: 'Buy new uniform', id: 6},
      {name: 'Apply for shift lead position', id: 7},
      {name: 'Take thursday off', id: 8},
    ]},
    {name: 'Life Admin', hue: 250, id: 'hobby', items: []},
  ]
};*/

function App() {
  const [model, setModel] = useState(m);

  return (
    <>
      <main>
        <ListNav model={model}></ListNav>
        <ListBox model={model}></ListBox>
      </main>
    </>
  );
}


export default App;
